<template>
    <div class="profile_card">
        <picture>
            <img src="../assets/thomas.jpg" alt="Thomas-Klubert der Lehrer">
        </picture>
        <div class="content">
            <h3>Yoga seit über 35 Jahren</h3>
            <p>
                Wenn Yoga, dann richtig, denn Yoga ist Vertrauenssache. Als erfahrener Yogalehrer kann ich Sie optimal begleiten.
            </p>
            <span class="sign">
                Thomas Klubert
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProfileCard"
    }
</script>

<style scoped lang="scss">
    @import '../scss/variables.scss';

    .profile_card {
        padding: 10px;
        background: $light;
        margin: -25px;
        margin-top: 25px;
        color: $primary;
        text-align: center;

        h3{
            margin-bottom: 10px;
            margin-top: 10px;
        }

        picture {
            flex-shrink: 0;

            img {
                max-height: 200px;
                margin: auto;
            }
        }
        .content {
            padding: 15px;
            padding-bottom: 0;
            position: relative;
            line-height: 1.2;
        }
        p{
            line-height: 1.5;

        }
        .sign {
            font-family: 'Great Vibes', cursive;
            font-size: 2rem;
            display: block;
        }
    }
    @media all and (min-width: 530px ) {

        .profile_card {
            display: flex;
            padding: 0;
            text-align: left;
        }
    }
    @media all and (min-width: $tablet ) {

        .profile_card {

            display: flex;
            position: absolute;
            right: 0;
            bottom: 0px;
            margin: 0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            overflow: hidden;
            max-width: 500px;

            .sign {
                position: absolute;
                right: 28px;
                bottom: 20px;
            }
        }
    }
</style>