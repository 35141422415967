<template>
    <div class="nav box-shadow">
        <div class="container">
            <div class="horizontal vertical-center">
                <div class="logo-section">
                    <logocircle></logocircle>
                    <img class="logo_name" src="../assets/logo_name.svg" alt="">
                </div>
                <div class="nav-items">
                    <ul>
                        <li><a href="#lehrer" v-scroll-to="'#lehrer'">Der Lehrer</a></li>
                        <li><a href="#yoga" v-scroll-to="'#yoga'">Yoga</a></li>
                        <li><a href="#biomeditation" v-scroll-to="'#biomeditation'">Biomotivation</a></li>
                        <li><a href="#angebote" v-scroll-to="'#angebote'">Angebote</a></li>
                        <li><a href="#kontakt" v-scroll-to="'#kontakt'">Kontakt</a></li>
                    </ul>
                </div>
                <div id="mobile-nav">
                    <div id="page-wrapper" :class="{active: active}" @click="toggleMobileNav">

                    </div>

                    <div id="btn" :class="{active: active}" @click="toggleMobileNav">
                        <div id='top'></div>
                        <div id='middle'></div>
                        <div id='bottom'></div>
                    </div>
                    <div id="box" :class="{active: active}" class="box-shadow">
                        <div id="items">
                            <a class="item" href="#lehrer" v-scroll-to="'#lehrer'">Der Lehrer</a>
                            <a class="item" href="#yoga" v-scroll-to="'#yoga'">Yoga</a>
                            <a class="item" href="#biomeditation" v-scroll-to="'#biomeditation'">Biomotivation</a>
                            <a class="item" href="#angebote" v-scroll-to="'#angebote'">Angebote</a>
                            <a class="item" href="#kontakt" v-scroll-to="'#kontakt'">Kontakt</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Logocircle from  '@/components/Logocircle'

    export default {
        data: function () {
            return  {
                active: false
            }
        },
        components: {
            Logocircle,
        },
        methods: {
            toggleMobileNav: function () {
                this.active = !this.active
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../scss/variables.scss';

    $black: #343838;
    $blue: #00DFFC;
    $easing: cubic-bezier(.6, .05, .28, .91);

    .nav {
        background: $background;
        position: fixed;
        z-index: 1000;
        width: 100%;
        .logo-section{
            position: relative;
            display: flex;
            padding: 10px 0;
            .logo_name {
                opacity: 0;
                width: 200px;
                margin: 10px auto;
                margin-left: 100px;
            }
        }
        .name-section{
            position: relative;
            margin-left: 40px;
            padding: 30px 0;
        }
        .nav-items {
            display: none;
            ul {
                display: flex;
                list-style-type: none;
                justify-content: space-between;
                padding: 0px;
                margin: 0px -15px;

                li {
                    display: block;
                    a {
                        display: block;
                        font-size: 18px;
                        padding: 10px;
                        transition: background-color 0.5s, color 0.5s;
                        &:hover {
                            background: $primary;
                            color: $background;
                        }
                    }
                }
            }
        }



        #mobile-nav {

            #page-wrapper.active {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparentize(#000000, .7);
                cursor: pointer;
            }


            #btn {
                position: fixed;
                z-index: 5;
                top: 20px;
                right: 15px;
                cursor: pointer;
                transition: right 500ms $easing;
                div {
                    width: 35px;
                    height: 2px;
                    margin-bottom: 8px;
                    background-color: $primary;
                    transition: transform 500ms $easing, opacity 500ms, background-color 250ms;
                }
            }

            #btn.active {
                right: 230px;
                #top {
                    transform: translateY(10px) rotate(-135deg);
                }
                #middle {
                    opacity: 0;
                    transform: rotate(135deg);
                }
                #bottom {
                    transform: translateY(-10px) rotate(-45deg);
                }
            }

            #box {
                position: fixed;
                z-index: 4;
                overflow: auto;
                top: 0px;
                right: -275px;
                width: 275px;
                opacity: 0;
                padding: 20px 0px;
                height: 100%;
                background-color: $background;
                color: $black;
                transition: all 350ms $easing;
            }

            #box.active {
                right: 0px;
                opacity: 1;
            }

            #items {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                .item {
                    display: block;
                    position: relative;
                    cursor: pointer;
                    font-size: 2em;
                    padding: 15px 30px;
                    transition: all 250ms;
                    &:hover {
                        padding: 15px 45px;
                        background-color: transparentize($primary, .8);
                    }
                }
            }

            #btn, #btn * {
                will-change: transform;
            }

            #box {
                will-change: transform, opacity;
            }
        }
    }
    @media all and (min-width: $medium ) {
        .nav {
            .logo-section {
                .logo_name {
                    opacity: 1;
                }
            }
        }
    }
    @media all and (min-width: $desktop ) {
        .nav {
            .logo-section{
                .logo_name {
                    margin-left: 170px;
                }
            }
            .nav-items {
                display: block;
                ul {
                    display: flex;
                    list-style-type: none;
                    justify-content: space-between;
                    padding: 0px;
                    margin: 0px -15px;

                    li {
                        display: block;
                        a {
                            display: block;
                            font-size: 18px;
                            padding: 30px;
                            transition: background-color 0.5s, color 0.5s;
                            &:hover {
                                background: $primary;
                                color: $background;

                            }
                        }
                    }
                }
            }
            #mobile-nav {
                display: none;
            }
        }
    }
</style>