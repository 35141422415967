<template>
    <div class="container">
        <div v-if="timeline" class="timeline">
            <div class="line"></div>
            <div class="lineDate">
                <div class="time" :key="index" v-for="(item , index ) in timeline" @click="toIndex(index)" :class="{active: active_index == index }">
                    {{ item.date }}
                </div>
            </div>
            <div class="items" :style="timelineStyle">
                <div class="item" :key="index"  v-for="(item , index) in timeline" >
                    <div class="key">
                        {{ item.date }}
                    </div>
                    <div class="item_content">
                        <nl2br v-if="item.content" tag="p" :text=item.content />
                        <p v-html="item.html"> </p>
                    </div>
                </div>
            </div>

            <div class="navigation">
                <span class="previous" @click="prev" ><img width="" class="logo" src="../assets/previous.svg" alt=""></span>
                <span class="next" @click="next" ><img width="" class="logo" src="../assets/next.svg" alt=""></span>
            </div>
        </div>
    </div>
</template>

<script>
    import Nl2br from 'vue-nl2br'
    export default {

        data () {
            return {
                active_index: 0,
                timeline: [
                    {
                        date: "1986",
                        content: "1986 beginnt mein Yogaweg, \n" +
                        "1988 erste Lehrtätigkeit\n" +
                        "1989 Ausbildungsbeginn mit Sharat Arora"
                    },
                    {
                        date:"1996",
                        content: "1996 Abschluss der 3,5 jährigen Yogalehrerausbildung (BDY)\n" +
                        "1996 Gründung der Yogaschule in Roetgen\n"
                    },
                    {
                        date:"1998",
                        content: "1998 Seminaraufenthalt in Indien"
                    },
                    {
                        date:"2001",
                        content: "2001 Abschluss der Ayurveda-Ausbildung bei Dr. Jeevan\n"
                    },
                    {
                        date:"2004",
                        content: "2004 Ausbildung zur Biomeditation bei Viktor Philippi\n" +
                        "regelmäßige Fortbildungen bei Sharat Arora (Iyengar-Yoga), Raja Gopalan (Sivananda-Yoga), Boris Tatzki (Yoga der Energie, Viniyoga), Viktor Philippi (Biomeditation) und vielen anderen Lehrern"
                    },
                    { date:"2011", content: "2011 Vipassana-Meditation" },
                    { date:"2016", content: "2016 Kontakt zu Reinhard Gammenthaler, Kundalini-Yoga-Parampara" },
                    { date:"2018", content: "2018 Einweihung bei Yogiraj SatGurunath Siddanath" }
                ]
            }
        },
        components: {
            Nl2br
        },
        methods: {
            toIndex: function(i) {
                this.active_index = i
            },
            next: function() {
                this.active_index ++
            },
            prev: function() {
                if( this.active_index == 0) {
                    this.active_index = this.timeline.length -1
                } else {
                    this.active_index --
                }
            }
        },
        computed: {
            timelineStyle: function() {
                var l = this.timeline.length*100
                var item = 100 / this.timeline.length
                let length = this.active_index % this.timeline.length
                var activePosition =  ( ( item * length ) * -1)
                return {
                    width: l+'%',
                    transform: 'translateX('+activePosition+'%)'
                }
            },
            classObject: function ( index ) {
                return {
                    active: this.active_index == index,
                }
            }
        }
    }
</script>

<style lang="scss">
    @import '../scss/variables.scss';
    .timeline {
        position: relative;
        overflow: hidden;
        height: 240px;
        max-width: 980px;
        margin: 15px auto;
        padding-top: 5px;
        font-size: 1.2rem;
        .time {
            cursor: pointer;
            position: relative;
            padding-left: 35px;

            @media all and (min-width: $phablet ) {
                padding-left: 0px;
            }

            &:before {
                position: absolute;
                content:"";
                width: 10px;
                height: 10px;
                top: 50%;
                left: 11px;
                transform: translateY(-50%);
                border-radius: 10px;
                background: $primary;
                @media all and (min-width: $phablet ) {
                    top: -6px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            &.active {
                &:before {
                    position: absolute;
                    content:"";
                    width: 14px;
                    height: 14px;
                    left: 9px;
                    @media all and (min-width: $phablet ) {
                        left: 50%;
                    }
                }
            }

        }

        .line {
            position: absolute;
            width: 3px;
            height: 100%;
            background: $primary;
            margin-left: 15px;

            @media all and (min-width: $phablet ) {
                position: relative;
                width: 100%;
                height: 3px;
                margin-left: 0;
            }
            &:before {
                position: absolute;
                content:"";
                width: 3px;
                height: 5px;
                top: -5px;
                left: 0;
                background: $primary;
            }
            &:after {
                position: absolute;
                content:"";
                width: 3px;
                height: 5px;
                top: -5px;
                right: 0;
                background: $primary;
            }
        }
        .lineDate {
            position: absolute;
            z-index: 100;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100px;
            font-weight: bold;
            @media all and (min-width: $phablet ) {
                width: 100%;
                flex-direction: row;
            }
        }
        .items{
            position: absolute;
            display: flex;
            transition: transform 0.5s;
            height: 100%;
            .item {
                position: relative;
                width: 100%;
                height: 100%;

                overflow: hidden;
                font-weight: bold;
                .key {
                    display: none;
                    position: absolute;
                    font-size: 8rem;
                    line-height: 15rem;
                    opacity: .1;
                    padding: 0px 30px;
                    @media all and (min-width: $phablet ) {
                        display: block;
                        font-size: 15rem;
                    }
                }
                .item_content {
                    position: absolute;
                    top: 0%;
                    right: 0;
                    text-align: right;
                    width: 80%;
                    padding: 0px 30px;
                    @media all and (min-width: $phablet ) {
                        top: 50%;
                        transform: translateY(-50%);
                        width: 100%;
                    }
                    p {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
        .navigation {
            display: none;
        }
        @media all and (min-width: $phablet ) {
            .navigation {
                position: absolute;
                display: flex;
                justify-content: space-between;
                width: 100%;
                top: 50%;
                transform: translateY(-50%);
                span {
                    cursor: pointer;
                }
            }
        }

        .hidden-mobile {
            display: none;
            @media all and (min-width: $phablet ) {
                display: block;
            }
        }
    }


</style>
