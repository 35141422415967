<template>
    <div class="relative intro">
        <div class="image_block ">
            <visual
                    :image="bgImage"
                    background='cover'
                    :aspect='16/9'
                    load='visible'
            >
            </visual>
            <storer :storer="storer"></storer>
        </div>

        <opening></opening>
    </div>
</template>

<script>
    import Visual from 'vue-visual'
    import Opening from '@/components/OpeningBlock'
    import Storer from '@/components/Storer'
    export default {
        data: function () {
            return {
                bgImage: require('../assets/intro.jpg'),
                storer: ' "In der Ruhe liegt die Kraft" '
            }
        },
        components: {
            Visual,
            Opening,
            Storer
        },
        computed: {
        }
    }
</script>

<style lang="scss">
    @import '../scss/variables.scss';

    .image_block {
        max-height: 500px;
        position: relative;
        overflow: hidden;

    }
    .intro {
        overflow: hidden;
    }
    @media all and (min-width: $desktop ) {

        .image_block{
            max-height: 700px;
        }
    }
</style>