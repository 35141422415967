<!--suppress ALL -->
<template>
    <visual
        :id="id"
        class="image_title"
            :image="simage"
            background='cover'
            load='visible'
    >
        <h2>{{ title }}</h2>
    </visual>

</template>

<script>
    import Visual from 'vue-visual'
    export default {
        data: function() {
            return {
            }
        },
        components: {
            Visual
        },
        props:  ['title', 'simage','id']
    }
</script>

<style scoped lang="scss">
    @import '../scss/variables.scss';

    .image_title {
        font-size: 62px;
        font-weight: bold;
        color: #ffffff;
        height: 150px;

        h2 {
            margin-bottom: 0;
            font-size: 1.5rem;
        }
    }

    @media all and (min-width: $tablet ) {
        .image_title {
            height: 250px;

            h2 {
                margin-bottom: 0;
                font-size: 2.5rem;
            }
        }
    }

</style>