<template>
    <div class="centered">
        <div class="form-box">
            <input placeholder="Email" v-model="email" type="text">
            <input placeholder="Passwort" v-model="password" type="password">
            <input class="button" type="submit" @click="signIn">
            <p v-if="error"> Es ist ein Fehler aufgtreten </p>
        </div>
    </div>

</template>

<script>
    import firebase from "@/utils/firebaseInit"

    export default {
        name: "Login",
        data: function () {
            return {
                email: '',
                password: '',
                error: ''
            }
        },
        methods: {
            signIn: function () {
                let that = this
                firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(
                    function () {
                        that.$router.push('/admin')
                    },
                    function (err) {
                        that.error = err
                    }
                )
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../scss/variables.scss';

    .form-box{
        display: flex;
        flex-direction: column;
        width: 250px;
        input {
            margin-bottom: 15px;
            padding: 10px 15px;
            border: $primary 1px solid;
            background: #fefefe;
            border-radius: 4px;
            transition: background 0.5s;
            &.button {
                background: $primary;
                color: white;
                &:hover {
                    color: $primary;
                    background: white;
                }
            }
        }
    }
    .centered {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100%;
    }
</style>
