<template>
    <div>
        <navigation></navigation>
        <intro></intro>
        <post :post="lehrer" :opened="true">
            <timeline></timeline>
        </post>
        <imagetitle
            :title="imagetitle1.title"
            :simage="imagetitle1.img"
        ></imagetitle>
        <post :post="yoga"></post>
        <imagetitle
            :title="imagetitle2.title"
            :simage="imagetitle2.img"
        ></imagetitle>
        <post :post="biomeditation"></post>
        <imagetitle
            :title="imagetitle3.title"
            :simage="imagetitle3.img"
        ></imagetitle>
        <post :post="angebote" :opened="true"></post>
        <imagetitle
            :id="imagetitle5.mainId"
            :title="imagetitle5.title"
            :simage="imagetitle5.img"
        ></imagetitle>
        <post :post="kontakt" :opened="true">
            <maps></maps>
        </post>
        <Footer></Footer>
    </div>
</template>

<script>
import firebase from '@/utils/firebaseInit';
const db = firebase.firestore();

import Navigation from '@/views/Navigation';
import Intro from '@/views/Intro';
import Imagetitle from '@/views/Imagetitle';
import Post from '@/views/Post';
import Timeline from '@/components/Timeline';
import Maps from '@/components/Maps';
import Footer from '@/views/Footer';
export default {
    created() {
        const yogaref = db.collection('angebote').doc('yoga');
        const bioRef = db.collection('angebote').doc('bio');

        let that = this;
        yogaref.get().then((doc) => {
            let data = doc.data();
            that.angebote.blocks.push({
                html: data.html,
            });
        });
        bioRef.get().then((doc) => {
            let data = doc.data();
            that.angebote.blocks.push({
                html: data.html,
            });
        });
    },
    data: function () {
        return {
            angeboteYoga: '',
            imagetitle1: {
                title: 'Yoga tut gut',
                img: require('@/assets/section1.jpg'),
            },
            imagetitle2: {
                title: 'Einfach nur zufrieden sein',
                img: require('@/assets/section4.jpg'),
            },
            imagetitle3: {
                title: 'Was brauchst Du gerade?',
                img: require('@/assets/section2.jpg'),
            },
            imagetitle4: {
                title: 'Es gibt nichts gutes, ausser ...',
                img: require('@/assets/section5.jpg'),
            },
            imagetitle5: {
                title: 'Die Zeit ist reif',
                mainId: 'apple',
                img: require('@/assets/section3.jpg'),
            },
            lehrer: {
                id: 'lehrer',
                title: 'Der Lehrer',
                subtitle: 'seit 35 Jahren auf dem Yogaweg',
                blocks: [
                    {
                        image: require('@/assets/lehrer.jpg'),
                        text: 'Traditionell unterrichtet Yoga nur einer, der Meister.',
                    },
                    {
                        text: 'Das ist heute völlig anders. In der Menge der Angebote ist es schwer, sich zu orientieren. Gerade wenn man ein konkretes Anliegen hat. ',
                    },
                    {
                        text: 'Kompetenz und Erfahrung sind entscheidend: Als hauptberuflicher Yogalehrer mit ca. 90.000 Kundenkontakten weiß ich, worauf es ankommt. Dabei ist mir persönliche Betreuung wichtig.',
                    },
                    {
                        text: 'Mein Yoga-Weg beginnt 1986. Ich wollte Yoga aus der Quelle lernen. So begegnete ich 1989 dem indischen Yogalehrer Sharat Arora – einem direkten Schüler von B.K.S. Iyengar. Er unterrichtete mich 27 Jahre!',
                    },
                    {
                        text: 'Bis heute interessiere ich mich für die verschiedenen Yoga-Richtungen und -Lehrer. Darum besuche ich immer noch unterschiedliche Fortbildungen, auf der Suche nach den effektivsten Yoga-Techniken. Durch meine eigene umfangreiche Praxis wird mein Yoga-Unterricht lebendig.',
                    },
                    {
                        text: 'Ich bin Familienvater, Diplom Betriebswirt und studierte soziale Arbeit.',
                    },
                ],
                list: [
                    { content: '1986 beginnt mein Yogaweg' },
                    { content: '1988 erste Lehrtätigkeit' },
                    { content: '1989 Ausbildungsbeginn mit Sharat Arora' },
                    {
                        content:
                            '1996 Abschluss der 3,5 jährigen Yogalehrerausbildung (BDY).',
                    },
                    { content: '1996 Gründung der Yogaschule in Roetgen' },
                    { content: '1998 Seminaraufenthalt in Indien' },
                    {
                        content:
                            '2001 Abschluss der Ayurveda-Ausbildung bei Dr. Jeevan',
                    },
                    {
                        content:
                            '2004 Ausbildung zur Biomeditation bei Viktor Philippi',
                    },
                    {
                        content:
                            'regelmäßige Fortbildungen bei Sharat Arora (Iyengar-Yoga), Raja Gopalan (Sivananda-Yoga), Boris Tatzki (Yoga der Energie, Viniyoga), Viktor Philippi (Biomeditation) und vielen anderen Lehrern',
                    },
                    { content: '2011 Vipassana-Meditation' },
                    {
                        content:
                            '2016 Kontakt zu Reinhard Gammenthaler, Kundalini-Yoga-Parampara',
                    },
                    {
                        content:
                            '2018 Einweihung bei Yogiraj SatGurunath Siddhanath',
                    },
                ],
            },
            yoga: {
                id: 'yoga',
                title: 'Yoga',
                subtitle: 'Yoga für Körper, Geist und Seele',
                blocks: [
                    {
                        image: require('@/assets/thumb.jpg'),
                        text: 'Yoga tut gut! In den Haltungen wird der Körper gestreckt und gedehnt, so können sich Spannungen lösen. Muskulatur wird aufgebaut. – Ein gutes Körpergefühl ist echte Lebensqualität. ',
                    },
                    {
                        text: 'Des Weiteren zielt Yoga darauf ab, vitaler zu werden. Es geht nicht wie beim Sport um „Auspowern“, sondern darum, Kräfte zu sammeln. ',
                    },
                    {
                        text: 'Yoga kann noch mehr: Es geht immer um Konzentration und Achtsamkeit. Der Geist wird dadurch geschult. So können wir Ruhe und Ausgeglichenheit entwickeln. ',
                    },
                    {
                        text: 'Starre und eingefahrene Denkmuster behindern uns im Alltag. Die Yogapraxis hilft uns freier und kreativer zu sein.',
                    },
                    {
                        text: 'Durch Büroarbeit oder andere einseitige Tätigkeiten sind wir oft  verspannt. Gerade das Iyengar-Yoga ist eine sehr intelligente Form der Körperübungen, denn viele Wirkungsbeziehungen sind  dort bekannt. Beispielsweise wird der Körper präzise in Ausrichtung gebracht. Das löst systematisch Spannungen und der Körper wird in seiner Haltung geschult. Ich gehe dabei besonders auf die Schwierigkeiten des Einzelnen ein. – So kommen wir gemeinsam ans Ziel.',
                    },
                    {
                        text: 'Auch andere Techniken des Yogas werden geübt, damit der Unterricht so wirkungsvoll wie möglich ist.',
                    },
                ],
            },
            biomeditation: {
                id: 'biomeditation',
                title: 'Biomotivation',
                subtitle: 'Willst Du den Körper heilen, musst Du die Seele heilen',
                blocks: [
                    {
                        imageRight: true,
                        image: require('@/assets/coaching.jpg'),
                        text: 'Die Biomotivation (Teil der Philippi-Methode) ist eine hocheffektive Technik der Energieübertragung. Hierzu wird die universelle Lebensenergie (bios: griechisch ”Leben“) zu Hilfe genommen. Bei dieser Methode durchfließt die Bioenergie das ganze Meridiansystem und baut dabei Blockaden ab.',
                    },
                    {
                        text: 'Blockaden stören den freien Fluss der Lebensenergie und sind die Ursache aller Disharmonien und Krankheiten in Körper, Geist und Seele.'
                    },
                    {
                        text: `Der Körper kann sich nur dann von seinen Krankheiten befreien, wenn die Blockade beseitigt ist, welche diese Krankheit verursacht hat. Werden die Blockaden gelöst, kann die Lebenskraft wieder frei strömen und der Körper aktiviert seine Selbstheilungskräfte.
Die Bioenergie aktiviert gleichzeitig die Abwehrkräfte des Organismus und stärkt das Immunsystem, den Stoffwechsel und das Nervensystem.`
                    },
                    {
                        text: 'Wirkungsvoll unterstützt wird diese Arbeit durch das „Gesunde Denken“.',
                    },
                ],
            },
            angebote: {
                id: 'angebote',
                title: 'Angebote',
                blocks: [],
            },
            kontakt: {
                id: 'kontakt',
                title: 'Kontakt',

                blocks: [
                    {
                        text: 'Bei Fragen oder Anmeldung können Sie sich gerne jederzeit bei mir melden.',
                    },
                    {
                        html:
                            "E-Mail: <a href='mailto:info@thomas-klubert.de'><b>info@thomas-klubert.de</b></a><br />" +
                            'Telefon:<b> 02471/ 99 03 49</b> <br />' +
                            'Adresse: Jennepeterstraße 30, 52159 Roetgen',
                    },
                ],
            },
        };
    },
    components: {
        Navigation,
        Intro,
        Imagetitle,
        Post,
        Timeline,
        Maps,
        Footer,
    },
};
</script>

<style scoped>
</style>

