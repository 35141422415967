<template>
    <div class="storer">
        <span>{{ storer }}</span>
    </div>
</template>

<script>
    export default {
        props: ['storer']
    }
</script>


<style scoped lang="scss">
    @import '../scss/variables.scss';

    .storer span {
        font-family: 'Great Vibes', cursive;
        font-size: 1.5rem;
        color: #ffffff;
        line-height: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 70%;
        text-align: center;
    }

    @media all and (min-width: $phablet ) {
        .storer span {
            transform: translate(-50%,-50%);
            font-size: 2.5rem;
            line-height: 3rem;
            white-space: pre-wrap;
        }
    }

    @media all and (min-width: $laptop ) {
        .storer span {
            transform: translate(-50%,-100%);
            font-size: 3rem;
            line-height: 5rem;
            white-space: pre-wrap;
        }
    }

    @media all and (min-width: $desktop ) {
        .storer span {
            font-size: 4rem;
            line-height: 6rem;
        }
    }
</style>