import Vue from 'vue'
import App from './App.vue'

import router from './router'

import 'vue-visual/index.css'
import './scss/variables.scss'
import './scss/style.scss'
import 'leaflet/dist/leaflet.css'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'

var VueScrollTo = require('vue-scrollto');

Vue.config.productionTip = false

Vue.use(VueScrollTo, {
    duration: 1000,
    offset: -60
})


new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
