<template>
    <transition name="modal">
        <div class="modal-mask" @click="closeModal">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <img  @click="closeModal" width="" class="close" src="../assets/close.svg" alt="">
                    <h2 class="modal-header">
                        <slot name="header">
                            default header
                        </slot>
                    </h2>

                    <div class="modal-body">
                        <slot name="body">
                            default body
                        </slot>
                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        methods: {
            closeModal: function (e) {
                if(e.target.className === 'modal-wrapper' ){
                    this.$emit('close')
                }
                if(e.target.className === 'close' ){
                    this.$emit('close')
                }
            }
        }
    }
</script>

<style lang="scss">
    @import '../scss/variables.scss';

    .close {
        cursor: pointer;
        position: absolute;
        top: 25px;
        right: 25px;
        color: $primary;
    }
    .modal-mask {
        position: fixed;
        z-index: 100000;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, .5);
        display: table;
        transition: opacity .3s ease;
    }

    .modal-wrapper {
        width: 100vw;
        height: 100vh;
        padding: 5%;
    }
    .modal-container {
        width: 100%;
        height: 100%;
        max-width: 980px;
        position: relative;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;
        color: $primary;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        transition: all .3s ease;
        font-family: Helvetica, Arial, sans-serif;
    }

    .modal-body {
        margin: 20px 0;
        max-height: 90%;
        overflow-y: auto;

        h3 {
            margin-bottom: 1.6rem;
        }
        p{
            margin-bottom: 2rem;
        }
    }

    .modal-default-button {
        float: right;
    }

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
</style>