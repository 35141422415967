<template>
    <div>
        <h3 v-if="block.headline" class="tac">{{ block.headline }}</h3>
        <div class="cf block " v-if="block.imageRight">
            <div>
                <div class="right image">
                    <img v-if="block.image" :src="block.image" :alt="block.imageDescription">
                    <span v-if="block.imageDescription" class="description">{{ block.imageDescription }}</span>
                </div>
                
                <div v-if="block.text">
                    <nl2br v-if="block.text" tag="p" :text=block.text />
                </div>
                <div v-else v-html="block.html"></div>
            </div>
        </div>
        <div class="block" v-else>
            <div>
                <div class="left image">
                    <img v-if="block.image" :src="block.image" :alt="block.imageDescription">
                    <span v-if="block.imageDescription" class="description">{{ block.imageDescription }}</span>
                </div>
                <div v-if="block.text">
                    <nl2br v-if="block.text" tag="p" :text=block.text />
                </div>
                <div v-else v-html="block.html"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import Nl2br from 'vue-nl2br'
    export default {
        props: ["block"],
        components: {
            Nl2br
        },
    }
</script>

<style lang="scss">
    @import '../scss/variables.scss';
    .block {
        margin-bottom: 12px;
        h2 {
            margin-bottom: 15px;
            margin-top: 20px;
        }
        h3 {
            margin-bottom: 15px;
        }
        img{
            width: 100%;
            height: auto;
        }

        .description {
            font-weight: bold;
        }
    }
    .left{
        float: none;
        padding: 0;
        text-align: center;
    }
    .right {
        float: none;
        padding: 0;
        text-align: center;
    }
    h3{
        margin-top: 30px;
        color: $primary;
    }
    @media all and (min-width: $medium ) {
        .left{
            float: left;
            padding-right: 35px;
        }
        .right {
            float: right;
            padding-left: 35px;
        }
        .block {
            img{
                max-width: 100%;
                width: auto;
                height: auto;
            }
        }

    }
</style>