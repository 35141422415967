<template>
    <div class="opening-block">
        <div class="container relative">
            <profilecard></profilecard>
        </div>
    </div>
</template>

<script>
import Profilecard from './ProfileCard'
    export default {
        components: {
            Profilecard
        }
    }
</script>

<style scoped lang="scss">
    @import '../scss/variables.scss';

    .opening-block {
        display: block;
        bottom: 0;
        width: 100%;
        left: 0;
        background: transparent;
        color: white;
        padding: 25px 0;

        h3 {
            margin-bottom: 5px;
            color: white;
        }
    }

    
    .times {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .block {
            flex-shrink: 0;
            &:first-child {
                padding-right: 50px;
            }
        }
    }
    @media all and (min-width: $tablet ) {
        .times {
            width: 50%;
            flex-wrap: wrap;
            flex-direction: column;
            .block {
                flex-shrink: 0;
                &:first-child {
                    padding-right: 50px;
                }
            }
        }
    }
    @media all and (min-width: $laptop ) {
        .times {
            width: 50%;
            flex-direction: row;
        }
        .opening-block {
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            // background: #{$primary}BF;
            color: white;
            padding: 25px 0;
        }
    }


</style>