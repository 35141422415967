import { render, staticRenderFns } from "./Storer.vue?vue&type=template&id=5687b48b&scoped=true&"
import script from "./Storer.vue?vue&type=script&lang=js&"
export * from "./Storer.vue?vue&type=script&lang=js&"
import style0 from "./Storer.vue?vue&type=style&index=0&id=5687b48b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5687b48b",
  null
  
)

export default component.exports