<template>
    <div class="tac">
        <h2>{{ title }}</h2>
        <h3>{{ subtitle }}</h3>
    </div>
</template>

<script>
    export default {
        props: ['title', 'subtitle']
    }
</script>


<style scoped lang="scss">
    @import '../scss/variables.scss';


</style>