import Vue from 'vue'
import Router from 'vue-router'
import Admin from './views/Admin'
import Login from './views/Login'
import Home from './views/Home'

import firebase from "./utils/firebaseInit"


Vue.use(Router)


const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/admin',
            name: 'admin',
            component: Admin,
            meta: {
                requiresAuth: true
            },
        }
    ]
})

router.beforeEach((to, from, next) => {
    const currentUser = firebase.auth().currentUser;
    const requiresAuth = to.matched.some((route) => route.meta.requiresAuth)

    if (requiresAuth && !currentUser) {
        next('login')
    } else {
        next()
    }
})

export default router
