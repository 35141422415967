<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss">
    @import './scss/variables.scss';

    #app {
        background: $background;
        min-height: 100vh;
    }
</style>
