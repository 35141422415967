<template>
    <div>
        <div
            v-if="opened"
            class="post"
            :id="post.id">

            <div class="container">
                <titlesubtitle :title="post.title" :subtitle="post.subtitle"></titlesubtitle>
                <p v-if="post.content" v-html="post.content"></p>
                <template v-if="post.blocks" >
                    <postblock v-for="block in post.blocks" :key="block.id" :block="block" />
                </template>
                <ul v-if="post.list" class="timeline-text">
                    <li class="item" :key="index"  v-for="(item , index) in post.list" >
                        <span>{{item.content}}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div v-else class="post" :id="post.id" :class="{ isOpen: open }" :style="{ maxHeight: max+ 'px' }">
            <div class="container">
                <titlesubtitle :title="post.title" :subtitle="post.subtitle"></titlesubtitle>
                <p v-if="post.content" v-html="post.content"></p>
                <template v-if="post.blocks" >
                    <postblock v-for="block in post.blocks" :key="block.id" :block="block" />
                </template>
                <ul v-if="post.list" class="timeline-text">
                    <li class="item" :key="index"  v-for="(item , index) in post.list" >
                        <span>{{item.content}}</span>
                    </li>
                </ul>
                <button v-if="!open" class="more" @click="toggle">mehr lesen</button>
            </div>
        </div>
        <slot></slot>
    </div>

</template>

<script>
    import Titlesubtitle from '@/components/Titlesubtitle'
    import Postblock from '@/components/Postblock'
    export default {
        props: ['post','opened'],
        data: function() {
            return {
                open: this.opened || false,
                max: 500
            }
        },
        computed: {
        },
        components: {
            Titlesubtitle,
            Postblock
        },
        methods: {
            toggle: function() {
                if( this.open ) {
                    this.open = false
                    this.max = 500
                }   else {
                    this.open = true
                    this.max = 5000
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    @import '../scss/variables.scss';
    .post{
        padding: 50px 0;
        overflow: hidden;
        position: relative;
        transition: max-height 0.5s;
        .timeline-text {
            list-style-position: inside;
            padding-left: 0;
        }
    }
    .more {
        position: absolute;
        bottom: 0px;
        left: 50%;
        color: $primary;
        background: none;
        border: none;
        text-decoration: underline;
        transform: translateX(-50%);
        font-weight: 700;
        display: block;
        width: 100%;

        height: 250px;

        &:before {
            position: absolute;
            content:"";
            width: 100%;
            height: 100%;
            top: 0px;
            left: 0;
            background: linear-gradient(transparent 0%,#{$background} 50%, $background 60%);
            z-index: -1;
        }
    }
</style>
