<template>
    <div>
        <div class="container"></div>
        <div id="map" class="map"></div>
    </div>
</template>

<script>
    import leaflet from 'leaflet'
    import 'leaflet-gesture-handling'
    export default {
        mounted() {
            this.initMap()
        },
        data () {
            return {
                map: null,
                tileLayer: null,
                marker: null,
                center: [50.645247, 6.192545],
                icon: null

            }
        },
        methods: {
            initMap: function () {
                this.map = leaflet.map('map', {
                    center: this.center,
                    gestureHandling: true,
                    zoom: 18
                })

                this.tileLayer = leaflet.tileLayer ('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                    center: this.center
                }).addTo(this.map);

                this.icon = leaflet.icon({
                    iconUrl: require('./../assets/marker.png'),
                    iconSize: [32, 32],
                    iconAnchor: [32,32],
                })
                this.marker = leaflet.marker(this.center, {
                    icon: this.icon
                }).addTo(this.map);
            }
        }
    }
</script>

<style lang="scss">

    @import '../scss/variables.scss';
    .map {
        height: 300px;
        width: 100%;
        overflow: hidden;
    }

</style>