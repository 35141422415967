<template>
    <div class="circle">
        <img width="" class="logo" src="../assets/logo.svg" alt="">
    </div>
</template>

<script>
    export default {
        data: function() {
            return {

            }
        }
    }
</script>

<style scoped lang="scss">
    @import '../scss/variables.scss';

    .circle {
        background: $background;
        border-radius: 100%;
        position: absolute;
        padding: 15px;
        padding-top: 0;
        max-width: 100px;

        img {
            width: 100px;
            height: auto;
        }
    }

    @media all and (min-width: $desktop ) {
        .circle {
            max-width: 150px;

            img {
                width: 150px;
            }
        }
    }
</style>