<template>
    <div>
        <div class="toolbar">
            <div class="mycontainer">
                <div class="user">
                    <div>Eingeloggt als <strong>{{ currentUser.email }}</strong></div>
                    <button class="button secondary" @click="signOut()">Ausloggen</button>
                </div>

                <button class="button" @click="save()">Speichern</button>

            </div>
        </div>
        <div class="mycontainer">
            <div class="block">
                <h2>Angebote Yoga</h2>
                <vue-editor class="editor" v-model="yoga.html" :editorToolbar="customToolbar" />
            </div>
            <div class="block">
                <h2>Angebote Biomeditation</h2>
                <vue-editor class="editor" v-model="bio.html" :editorToolbar="customToolbar" />
            </div>
        </div>
    </div>
</template>

<script>

    import { VueEditor } from 'vue2-editor'
    import firebase from "@/utils/firebaseInit"
    const db = firebase.firestore()

    export default {
        created () {
            this.getAngebote()
        },
        data: function () {
            return {
                yoga: '',
                bio: '',
                customToolbar: [
                    [{ 'header': [1, 2, 3, false] }],
                    ['bold', 'italic', 'underline'],
                    [{ 'list': 'bullet' }],
                    [{ 'align': [] }],
                    [{ 'indent': '-1'}, { 'indent': '+1' }]
                ]
            }
        },
        components: {
            VueEditor
        },
        methods: {
            getAngebote: function () {
                const yogaref = db.collection('angebote').doc('yoga');
                let that = this;
                yogaref.get()
                .then(doc => {
                    that.yoga = doc.data()
                })
                const bioRef = db.collection('angebote').doc('bio');
                bioRef.get()
                    .then(doc => {
                        that.bio = doc.data()
                    })
            },
            save: function () {
                db.collection('angebote').doc('bio').set({
                    html: this.bio.html,
                    user: this.currentUser.email,
                    date: Date.now()
                });
                db.collection('angebote').doc('yoga').set({
                    html: this.yoga.html,
                    user: this.currentUser.email,
                    date: Date.now()
                });
            },
            signOut: function () {
                let that = this
                firebase.auth().signOut().then( () => {
                    that.$router.push('/')
                })
            }
        },
        computed: {
            currentUser: function () {
                return firebase.auth().currentUser
            }
        }
    }
</script>

<style lang="scss">
    @import '../scss/variables.scss';

    .toolbar {
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: #ffffff;
        box-shadow: 1px 1px 5px #00000065;
        z-index: 100;
        padding: 20px 0;
        .button {
            border: 1px solid $primary;
            padding: 10px 15px;
            transition: background 0.5s;
            background: $primary;
            color: white;
            &.secondary {
                padding: 5px 10px;
                background: $danger;
                border-color: $danger;
            }
            &:hover {
                color: $primary;
                background: white;
                border-color: $primary;
            }
        }
    }
    .mycontainer {
        padding-top: 100px;
        display: flex;
        justify-content: space-between;
        max-width: 1280px;
        margin: auto;
        padding: 20px;
        @media all and (max-width: 850px) {
            flex-direction: column;
        }
        .block {
            max-width: 48%;
            @media all and (max-width: 850px) {
                max-width: 100%;
            }
            .editor {
                .ql-toolbar {
                    background: lighten($background,5)
                }
                .ql-container {
                    background: white;
                    height: 500px;
                }
            }
        }
    }
</style>
